<template>
  <div class="card mt-4">
    <div class="card-header">
      <div class="row">
        <div class="col">
          <strong><span class="ion ion-ios-people" /> {{ $t('dashboard.holdersChartCard.title') }}</strong>
        </div>
        <div class="col d-flex justify-content-end">
          <!--<a href="#" class="btn btn-outline-primary btn-sm">
            More Details
          </a>-->
        </div>
      </div>
    </div>

    <div class="card-body p-0">
      <div class="row no-gutters row-bordered row-border-light h-100 align-items-center">
        <div class="col-md-4">
          <div class="card-body media align-items-center text-dark">
            <i class="ion ion-md-globe display-4 d-block text-primary" />
            <span class="media-body d-block ml-3">
              <span class="text-big font-weight-bolder">
                <cp-formatted-number :value="holdersStatistics.totalUSHolders" />
              </span><br>
              <small class="text-muted">
                {{ $t('dashboard.holdersChartCard.label.usHolders') }}
              </small>
            </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card-body media align-items-center text-dark">
            <i class="ion ion-md-globe display-4 d-block text-primary" />
            <span class="media-body d-block ml-3">
              <span class="text-big font-weight-bolder">
                <cp-formatted-number :value="holdersStatistics.totalEUHolders" />
              </span><br>
              <small class="text-muted">
                {{ $t('dashboard.holdersChartCard.label.euHolders') }}
              </small>
            </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card-body media align-items-center text-dark">
            <i class="ion ion-md-globe display-4 d-block text-primary" />
            <span class="media-body d-block ml-3">
              <span class="text-big font-weight-bolder">
                <cp-formatted-number :value="holdersStatistics.totalHolders" />
              </span><br>
              <small class="text-muted">
                {{ $t('dashboard.holdersChartCard.label.worldwideHolders') }}
              </small>
            </span>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-0 mx-0">

    <div
      v-if="showChartsArea && !isDataReady"
      class="row align-items-center mb-2 card-loader"
    >
      <div class="col-md-12 text-center mt-4">
        {{ $t('dashboard.holdersChartCard.loading') }}
      </div>
    </div>

    <div
      v-if="showChartsArea && isDataReady"
      class="card-body p-0"
    >
      <div class="row no-gutters row-bordered">
        <div class="col-md-4">
          <cp-chart :options="countriesOptions" />
        </div>
        <div class="col-md-4">
          <cp-chart :options="typeOptions" />
        </div>
        <div class="col-md-4">
          <div
            v-if="canViewMaximumStakes"
            class="card-body"
          >
            <h5>{{ $t('dashboard.holdersChartCard.label.maximumStakes') }}</h5>
            <div
              v-for="(stake, index) in holdersTokenStakes"
              :key="index"
              class="row"
            >
              <div class="col-6 text-muted mb-3">
                <a
                  href="javascript:void(0)"
                  @click="toViewPage(stake, $event)"
                >
                  {{ stake.holderName }}
                </a>
              </div>
              <div class="col-6 mb-3">
                <span class="text-big">
                  <cp-formatted-number :value="stake.quantity" />
                  {{ $t('dashboard.holdersChartCard.label.tokens') }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import CpChart from '~/components/common/statistic';
import CpCountryName from '~/components/common/country-name';
import CpFormattedNumber from '~/components/common/filters/number-formatter';
import routeNames from '~/utilities/routes';

const chartColors = ['#123047', '#507590', '#6ea1c6', '#3287c6', '#1f537a'];

export default {
  name: 'DashboardHolders',
  components: {
    CpChart,
    CpFormattedNumber,
  },
  props: {
    tokenId: {
      type: [Number, String],
      default: undefined,
    },
  },
  data() {
    return {
      isDataReady: false,
      showChartsArea: true,
      countriesOptions: {
        color: {
          pattern: chartColors,
        },
        data: {
          columns: [],
          type: 'donut',
          onclick: (data) => {
            this.searchNavigateCountry(data.id);
          },
        },
        tooltip: {
          format: {
            name: name => CpCountryName.getCountryName(name) || name,
          },
        },
        donut: { title: this.$t('dashboard.chart.title.countries') },
        transition: {
          duration: 500,
        },
      },
      typeOptions: {
        color: {
          pattern: chartColors,
        },
        data: {
          columns: [],
          type: 'donut',
          onclick: (data) => {
            this.searchNavigateType(data.id.toLowerCase());
          },
        },
        donut: { title: this.$t('dashboard.chart.title.type') },
        transition: {
          duration: 500,
        },
      },
    };
  },
  computed: {
    ...mapGetters('dashboard', [
      'holdersStatistics',
      'holdersByCountrySummary',
      'holdersByTypeSummary',
      'holdersTokenStakes',
    ]),
    ...mapGetters('issuersInfo', ['permissionsPerIssuer']),
    canViewMaximumStakes() {
      return !this.permissionsPerIssuer.isBDRestrictedAccess;
    },
  },
  watch: {
    tokenId: {
      immediate: true,
      handler() {
        this.updateHoldersInfo();
      },
    },
  },
  methods: {
    ...mapActions('dashboard', ['getDashboardHoldersInfo']),
    ...mapMutations('global', ['SET_FILTER_STATE']),
    async updateHoldersInfo() {
      this.isDataReady = false;

      await this.getDashboardHoldersInfo({
        issuerId: this.$route.params.idIssuer,
        tokenId: this.$route.params.tokenId,
      });

      this.countriesOptions.data.columns = this.parseList(this.holdersByCountrySummary);
      this.typeOptions.data.columns = this.parseList(this.holdersByTypeSummary);
      this.isDataReady = true;
    },
    searchNavigateCountry(country) {
      this.SET_FILTER_STATE({
        name: 'investorsHolders',
        filterData: { 'country-code': country === 'Rest' ? '' : country },
      });
      this.redirectToPage('holders');
    },
    searchNavigateType(type) {
      let investorType = type;
      if (type === 'entity') {
        investorType = 'entity';
      }
      this.SET_FILTER_STATE({
        name: 'investorsHolders',
        filterData: { 'investor-type': investorType },
      });
      this.redirectToPage('holders');
    },
    redirectToPage(pageName) {
      this.$router.push(routeNames[pageName](this.$route.params.idIssuer, this.tokenId));
    },
    toViewPage(investor, $event) {
      const investorId = investor.holder.id;
      const { idIssuer, tokenId } = this.$route.params;
      const routeData = this.$router.resolve(routeNames.viewDetails(idIssuer, tokenId, investorId));
      if ($event.metaKey) {
        window.open(routeData.href);
      } else {
        this.$router.push(routeData.href);
      }
    },
    capitalize(str) {
      return str[0].toUpperCase() + str.slice(1);
    },
    parseList(list) {
      return list.map((item) => {
        let name;
        if (['entity', 'individual'].includes(item.name)) {
          name = this.$t(`common.category.investorType.${item.name}`);
        } else if (item.name === 'rest') {
          name = this.$t('dashboard.chart.legend.rest');
        } else {
          name = this.capitalize(item.name);
        }

        return [
          name,
          Number(item.percentage),
        ];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .card-loader {
    min-height: 280px;
  }
</style>
