<template>
  <div class="card mt-4">
    <div class="card-header">
      <div class="row">
        <div class="col">
          <strong><span class="ion ion-ios-stats" /> {{ $t('dashboard.fundraiseChartCard.title') }}</strong>
        </div>
      </div>
    </div>

    <div
      v-if="isDataReady"
      class="card-body p-0"
    >
      <div class="row no-gutters row-bordered row-border-light h-100 align-items-center">
        <div class="col-md-4">
          <div class="card-body media align-items-center text-dark">
            <i class="ion ion-ios-timer display-4 d-block text-primary" />
            <span class="media-body d-block ml-3">
              <span class="text-big font-weight-bolder">
                <span
                  class="badge badge-dot"
                  :class="`badge-${statusClass}`"
                />
                {{ fundingStatisticsStatus }}
              </span><br>
              <small class="text-muted">
                {{ $t('dashboard.fundraiseChartCard.label.status') }}
              </small>
            </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card-body media align-items-center text-dark">
            <i class="ion ion-ios-checkbox-outline display-4 d-block text-primary" />
            <span class="media-body d-block ml-3">
              <span class="text-big font-weight-bolder">
                {{ issuerMainCurrencyIdentifier | currencySymbol }} <cp-formatted-number :value="fundingStatistics.amountPledged" />
              </span><br>
              <small class="text-muted">
                {{ $t('dashboard.fundraiseChartCard.label.amountPledged') }}
              </small>
            </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card-body media align-items-center text-dark">
            <i class="ion ion-ios-cash display-4 d-block text-primary" />
            <span class="media-body d-block ml-3">
              <span class="text-big font-weight-bolder">
                {{ issuerMainCurrencyIdentifier | currencySymbol }} <cp-formatted-number :value="fundingStatistics.amountRaised" />
              </span><br>
              <small class="text-muted">
                {{ $t('dashboard.fundraiseChartCard.label.amountRaised') }}
              </small>
            </span>
          </div>
        </div>
      </div>
    </div>

    <hr class="my-0 mx-0">

    <div
      v-if="isDataReady"
      class="card-body py-3 px-5"
    >
      <div class="row">
        <div class="col-md-6 col-lg-8">
          <cp-chart :options="barOptions" />
        </div>
        <div class="col-md-6 col-lg-4">
          <cp-chart :options="pieOptions" />
        </div>
      </div>
    </div>

    <div
      v-else
      class="row align-items-center mb-2 card-loader"
    >
      <div class="col-md-12 text-center mt-4">
        {{ $t('dashboard.fundraiseChartCard.loading') }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters, mapActions, mapMutations, mapState,
} from 'vuex';
import CpChart from '~/components/common/statistic';
import CpFormattedNumber from '~/components/common/filters/number-formatter';
import CpCountryName from '~/components/common/country-name';
import routeNames from '~/utilities/routes';

const chartColors = ['#123047', '#507590', '#6ea1c6', '#3287c6', '#1f537a'];

export default {
  name: 'DashboardFundraise',
  components: {
    CpChart,
    CpFormattedNumber,
  },
  filters: {
    capitalize(value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  props: {
    roundId: {
      type: [Number, String],
      default: undefined,
    },
    timezone: {
      type: [Number, String],
      default: 0,
    },
    tokenId: {
      type: [Number, String],
      default: undefined,
    },
  },
  data() {
    return {
      isDataReady: false,
      barOptions: {
        color: {
          pattern: chartColors,
        },
        data: {
          x: 'x',
          columns: [],
          type: 'bar',
        },
        axis: {
          x: {
            type: 'timeseries',
          },
        },
        transition: {
          duration: 500,
        },
      },
      pieOptions: {
        color: {
          pattern: chartColors,
        },
        data: {
          columns: [],
          type: 'pie',
          onclick: (data) => {
            this.searchNavigate(data.id);
          },
        },
        transition: {
          duration: 500,
        },
        tooltip: {
          format: {
            name: name => CpCountryName.getCountryName(name) || name,
          },
        },
      },
    };
  },
  computed: {
    ...mapState('global', ['issuerMainCurrencyIdentifier']),
    ...mapGetters('dashboard', [
      'fudingByCountrySummary',
      'fundingByPledgeSummary',
      'fundingFundedSummary',
      'fundingStatistics',
    ]),
    statusClass() {
      return this.fundingStatistics.status === 'active' ? 'success' : 'danger';
    },
    parsedFundraise() {
      return this.fudingByCountrySummary.map(item => [
        item.countryCode === 'rest' ? this.$t('dashboard.chart.legend.rest') : item.countryCode,
        item.quantity,
      ]);
    },
    dateAxis() {
      const parsed = this.fundingByPledgeSummary.map(item => item.date);
      parsed.unshift('x');
      return parsed;
    },
    fundingStatisticsStatus() {
      return this.$t(`dashboard.fundraiseChartCard.status.${this.fundingStatistics.status}`);
    },
  },
  watch: {
    roundId() {
      this.updateFundraiseInfo();
    },
  },
  methods: {
    ...mapActions('dashboard', ['getDashboardFundraiseInfo']),
    ...mapMutations('global', ['SET_FILTER_STATE']),
    searchNavigate(country) {
      this.SET_FILTER_STATE({
        name: 'investorsFundraise',
        filterData: { 'country-code': country.toLowerCase() === 'rest' ? undefined : country },
      });
      this.redirectToPage('fundraise');
    },

    redirectToPage(pageName) {
      this.$router.push(routeNames[pageName](this.$route.params.idIssuer, this.tokenId));
    },

    async updateFundraiseInfo() {
      this.isDataReady = false;

      await this.getDashboardFundraiseInfo({
        issuerId: this.$route.params.idIssuer,
        roundId: this.roundId,
        tokenId: this.$route.params.tokenId,
        timezone: this.timezone,
      });

      this.barOptions.data.columns = [
        this.dateAxis,
        this.parseToChartOptions(this.fundingByPledgeSummary, this.$t('dashboard.chart.legend.pledged')),
        this.parseToChartOptions(this.fundingFundedSummary, this.$t('dashboard.chart.legend.funded')),
      ];
      this.$set(this.pieOptions.data, 'columns', this.parsedFundraise);

      setTimeout(() => {
        this.isDataReady = true;
      });
    },

    parseToChartOptions(list, name) {
      const parsed = list.map(item => item.value);
      parsed.unshift(name);
      return parsed;
    },
  },
};
</script>

<style lang="scss" scoped>
  .card-loader {
    min-height: 280px;
  }
</style>
