<template>
  <div class="row no-gutters row-bordered row-bordered-light">
    <div class="dashboard-content">
      <div class="p-4">
        <div class="card">
          <div class="card-body">
            <cp-round-select
              v-model="currentRound"
              is-all-round-option-enabled
              @input="handleRoundSelect"
            />
          </div>
        </div>
        <cp-funding-chart-card
          :round-id="currentRound"
          :token-id="tokenId"
          :timezone="timezone"
        />
        <cp-onboarding-chart-card
          :timezone="timezone"
        />
        <cp-holders-chart-card
          :token-id="tokenId"
        />
      </div>
    </div>
    <cp-activities />
  </div>
</template>

<script>
import moment from 'moment';
import CpHoldersChartCard from './components/holders-chart-card';
import CpFundingChartCard from './components/fundraise-chart-card';
import CpActivities from './components/activities';
import CpOnboardingChartCard from './components/onboarding-chart-card';
import CpRoundSelect from '~/components/common/round-select';

export default {
  name: 'CpDashboard',
  metaInfo: {
    title: 'Dashboard',
  },
  components: {
    CpActivities,
    CpOnboardingChartCard,
    CpHoldersChartCard,
    CpFundingChartCard,
    CpRoundSelect,
  },
  data() {
    return {
      currentRound: undefined,
      minutesInHour: 60,
    };
  },
  computed: {
    tokenId() {
      return this.$route.params.tokenId;
    },
    timezone() {
      return moment().utcOffset() / this.minutesInHour;
    },
  },
  beforeMount() {
    const rounds = this.$store.getters['rounds/roundsOption'];
    this.currentRound = rounds[rounds.length - 1] && rounds[rounds.length - 1].id;
  },
  methods: {
    handleRoundSelect(round) {
      this.currentRound = round;
    },
  },
};
</script>

<style lang="scss">
  .dashboard-content {
    width: calc(100% - 350px);
  }
</style>
