<template>
  <div class="card mt-4">
    <div class="card-header">
      <div class="row">
        <div class="col">
          <strong><span class="ion ion-ios-people" /> {{ $t('dashboard.onboardingChartCard.title') }}</strong>
        </div>
        <div class="col d-flex justify-content-end" />
      </div>
    </div>

    <div
      v-if="isDataReady"
      class="card-header p-0"
    >
      <div class="row no-gutters row-bordered row-border-light h-100 align-items-center">
        <div class="col-md-4">
          <div class="card-body media align-items-center text-dark">
            <i class="ion ion-ios-people display-4 d-block text-primary" />
            <span class="media-body d-block ml-3">
              <span class="text-big font-weight-bolder">
                <cp-formatted-number :value="onboardingStatistics.totalRegistered" />
              </span><br>
              <small class="text-muted">
                {{ $t('dashboard.onboardingChartCard.label.totalRegistered') }}
              </small>
            </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card-body media align-items-center text-dark">
            <i class="ion ion-ios-people display-4 d-block text-primary" />
            <span class="media-body d-block ml-3">
              <span class="text-big font-weight-bolder">
                <cp-formatted-number :value="onboardingStatistics.totalPledged" />
              </span><br>
              <small class="text-muted">
                {{ $t('dashboard.onboardingChartCard.label.totalPledged') }}
              </small>
            </span>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card-body media align-items-center text-dark">
            <i class="ion ion-ios-people display-4 d-block text-primary" />
            <span class="media-body d-block ml-3">
              <span class="text-big font-weight-bolder">
                <cp-formatted-number :value="onboardingStatistics.totalFunded" />
              </span><br>
              <small class="text-muted">
                {{ $t('dashboard.onboardingChartCard.label.totalFunded') }}
              </small>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!isDataReady"
      class="row align-items-center mb-2 card-loader"
    >
      <div class="col-md-12 text-center mt-4">
        {{ $t('dashboard.onboardingChartCard.loading') }}
      </div>
    </div>

    <div
      v-else
      class="card-body py-3 px-5"
    >
      <div class="row">
        <div class="col-md-6 col-lg-8">
          <cp-chart :options="splineOptions" />
        </div>
        <div class="col-md-6 col-lg-4">
          <cp-chart :options="donutOptions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import CpChart from '~/components/common/statistic';
import CpFormattedNumber from '~/components/common/filters/number-formatter';
import CpCountryName from '~/components/common/country-name';
import routeNames from '~/utilities/routes';

const chartColors = ['#123047', '#507590', '#6ea1c6', '#3287c6', '#1f537a'];

export default {
  name: 'CpOnboardingChart',
  components: {
    CpChart,
    CpFormattedNumber,
  },
  props: {
    timezone: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      isDataReady: false,
      donutOptions: {
        color: {
          pattern: chartColors,
        },
        data: {
          columns: [],
          type: 'donut',
          onclick: (data) => {
            this.searchNavigate(data.id);
          },
        },
        tooltip: {
          format: {
            name: name => CpCountryName.getCountryName(name) || name,
          },
        },
        donut: { title: this.$t('dashboard.chart.title.countries') },
        transition: {
          duration: 500,
        },
      },
      splineOptions: {
        color: {
          pattern: chartColors,
        },
        data: {
          x: 'x',
          columns: [],
          type: 'line',
        },
        axis: {
          x: {
            type: 'timeseries',
          },
          y: {
            padding: {
              top: 2,
              bottom: 3,
            },
            tick: {},
          },
        },
        transition: {
          duration: 500,
        },
      },
    };
  },
  computed: {
    ...mapGetters('dashboard', [
      'onboardingByCountrySummary',
      'onboardingRegisteredSummary',
      'onboardingAuthorizedSummary',
      'onboardingStatistics',
    ]),
    parsedOnboarding() {
      return this.onboardingByCountrySummary.map(item => [
        item.countryCode === 'rest' ? this.$t('dashboard.chart.legend.rest') : item.countryCode,
        +item.quantity,
      ]);
    },
    dateAxis() {
      const parsed = this.onboardingRegisteredSummary.map(item => item.date);
      parsed.unshift('x');
      return parsed;
    },
    parsedRegistered() {
      return this.parseToChartOptions(this.onboardingRegisteredSummary, this.$t('dashboard.chart.legend.registered'));
    },
    parsedAuthorized() {
      return this.parseToChartOptions(this.onboardingAuthorizedSummary, this.$t('dashboard.chart.legend.authorized'));
    },
  },
  async beforeMount() {
    await this.updateOnboardingInfo();
  },
  methods: {
    ...mapActions('dashboard', ['getDashboardOnboardingInfo']),
    ...mapMutations('global', ['SET_FILTER_STATE']),
    searchNavigate(country) {
      this.SET_FILTER_STATE({
        name: 'investorsOnboarding',
        filterData: { 'country-code': country.toLowerCase() === 'rest' ? '' : country },
      });
      this.redirectToPage('onboarding');
    },
    redirectToPage(pageName) {
      this.$router.push(routeNames[pageName](this.$route.params.idIssuer));
    },
    async updateOnboardingInfo() {
      this.isDataReady = false;
      await this.getDashboardOnboardingInfo({
        issuerId: this.$route.params.idIssuer,
        timezone: this.timezone,
      });

      this.donutOptions.data.columns = this.parsedOnboarding;
      this.splineOptions.data.columns = [this.dateAxis, this.parsedRegistered, this.parsedAuthorized];

      // set Y axis ticks
      const yTicks = this.getYTicks(
        [...this.parsedRegistered.slice(1), ...this.parsedAuthorized.slice(1)],
      );

      if (yTicks[yTicks.length - 1] >= 20) {
        this.splineOptions.axis.y.ticks = 20;
      } else {
        this.splineOptions.axis.y.tick.values = yTicks;
      }

      this.isDataReady = true;
    },
    parseToChartOptions(list, title) {
      const parsed = list.map(item => item.value);
      parsed.unshift(title);
      return parsed;
    },
    getYTicks(list) {
      const max = list.reduce(
        (dist, val) => (val > dist ? val : dist),
        0,
      );
      return new Array(max).fill(0).map((val, index) => index + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
  .card-loader {
    min-height: 280px;
  }
</style>
