<template>
  <div class="col-xl-3 m-lg-4 m-xl-0 card dashboard-activities">
    <div class="card-header">
      <strong><span class="ion ion-ios-list" />
        {{ $t("dashboard.activities.title") }}</strong>
    </div>
    <div class="card-body">
      <template v-for="log in activities">
        <div
          v-if="log.type === 'new-investor-registered'"
          :key="log.id"
          class="media pb-1 mb-4"
        >
          <span class="d-block ion ion-ios-contact" />
          <div class="media-body ml-3">
            <h6 class="mb-0">
              {{ $t("dashboard.activities.label.newInvestorRegistered") }}
              <router-link
                href="#"
                :to="getPagePath('viewDetails', log.investor.id)"
              >
                {{ getName(log.investor) }}
              </router-link>
            </h6>
            <span class="text-muted small">{{
              formatDate(log.createdAt)
            }}</span>
          </div>
        </div>

        <div
          v-else-if="log.type === 'new-investor-from-OFN'"
          :key="log.id"
          class="media pb-1 mb-4"
        >
          <span class="d-block ion ion-ios-contact" />
          <div class="media-body ml-3">
            <i18n
              path="dashboard.activities.label.newInvestorFrom"
              tag="h6"
              class="mb-0"
            >
              <template v-slot:from>
                <a
                  href="javascript:void(0)"
                  class="badge badge-outline-primary"
                >
                  {{ $t("dashboard.activities.label.openFinance") }}
                </a>
              </template>
              <template v-slot:investor>
                <router-link
                  href="#"
                  :to="getPagePath('viewDetails', log.investor.id)"
                >
                  {{ getName(log.investor) }}
                </router-link>
              </template>
            </i18n>
            <span class="text-muted small">{{
              formatDate(log.createdAt)
            }}</span>
          </div>
        </div>

        <div
          v-else-if="log.type === 'funds-received'"
          :key="log.id"
          class="media pb-1 mb-4"
        >
          <span class="d-block ion ion-ios-cash" />
          <div class="media-body ml-3">
            <i18n
              path="dashboard.activities.label.fundsReceivedFrom"
              tag="h6"
              class="mb-0"
            >
              <template v-slot:from>
                <router-link
                  href="#"
                  :to="getPagePath('viewDetails', log.investor.id)"
                >
                  {{ getName(log.investor) }}
                </router-link>
              </template>
              <template v-slot:amount>
                <cp-formatted-number :value="log.amount" />
                {{ log.currency.identifier }}
              </template>
            </i18n>
            <span class="text-muted small">{{
              formatDate(log.createdAt)
            }}</span>
          </div>
        </div>

        <div
          v-else-if="log.type === 'subscription-signed'"
          :key="log.id"
          class="media pb-1 mb-4"
        >
          <span class="d-block ion ion-ios-create" />
          <div class="media-body ml-3">
            <i18n
              path="dashboard.activities.label.subscriptionSignedBy"
              tag="h6"
              class="mb-0"
            >
              <template v-slot:by>
                <router-link
                  href="#"
                  :to="getPagePath('viewDetails', log.investor.id)"
                >
                  {{ getName(log.investor) }}
                </router-link>
              </template>
              <template v-slot:amount>
                <cp-formatted-number :value="log.amount" />
                {{ log.currency.identifier }}
              </template>
            </i18n>
            <span class="text-muted small">
              {{ formatDate(log.createdAt) }}
            </span>
          </div>
        </div>

        <div
          v-else-if="log.type === 'new-signature-required'"
          :key="log.id"
          class="media pb-1 mb-4"
        >
          <span class="d-block ion ion-ios-today" />
          <div class="media-body ml-3">
            <i18n
              tag="h6"
              path="dashboard.activities.label.newSignatureRequired.path"
            >
              {{ $t("dashboard.activities.label.newSignatureRequired.for") }}
            </i18n>
            <span class="text-muted small">
              {{ formatDate(log.createdAt) }}
            </span>
          </div>
        </div>

        <div
          v-else-if="log.type === 'wallet-registered'"
          :key="log.id"
          class="media pb-1 mb-4"
        >
          <span class="d-block ion ion-md-wallet" />
          <div class="media-body ml-3">
            <i18n
              tag="h6"
              path="dashboard.activities.label.walletRegisteredBy"
            >
              <router-link
                href="#"
                :to="getPagePath('viewDetails', log.investor.id)"
              >
                {{ getName(log.investor) }}
              </router-link>
            </i18n>
            <span class="text-muted small">
              {{ formatDate(log.createdAt) }}
            </span>
          </div>
        </div>

        <div
          v-else-if="log.type === 'investor-requiring-manual-review'"
          :key="log.id"
          class="media pb-1 mb-4"
        >
          <span class="d-block ion ion-ios-person" />
          <div class="media-body ml-3">
            <h6 class="mb-0">
              {{
                $t("dashboard.activities.label.investorRequiringManualReview")
              }}
              <router-link
                href="#"
                :to="getPagePath('viewDetails', log.investor.id)"
              >
                {{ getName(log.investor) }}
              </router-link>
            </h6>
            <span class="text-muted small">
              {{ formatDate(log.createdAt) }}
            </span>
          </div>
        </div>
      </template>
    </div>

    <a
      v-if="limit <= activities.length"
      href="javascript:void(0)"
      class="card-footer d-block text-center text-dark small font-weight-semibold"
      @click.prevent="increaseLimit"
    >
      {{ $t("dashboard.activities.button.showMore") }}
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import $moment from 'moment';
import routeNames from '~/utilities/routes';
import CpFormattedNumber from '~/components/common/filters/number-formatter';

export default {
  name: 'CpActivities',
  components: { CpFormattedNumber },
  data() {
    return {
      limit: 25,
    };
  },
  computed: {
    ...mapGetters('dashboard', ['activities', 'activitiesCount']),
    ...mapGetters('currentOperator', ['authorizationLevel']),
  },
  beforeMount() {
    this.getLogs({
      issuerId: this.$route.params.idIssuer,
      limit: this.limit,
    });
  },
  methods: {
    getName({ firstName, lastName }) {
      return `${firstName} ${lastName}`;
    },
    increaseLimit() {
      this.limit += 25;
      this.getLogs();
    },
    getLogs() {
      if (this.authorizationLevel !== 'brokerdealer') {
        this.$store.dispatch('dashboard/getLogs', {
          issuerId: this.$route.params.idIssuer,
          limit: this.limit,
        });
      }
    },
    formatDate(date) {
      return $moment(date).from($moment());
    },
    getPagePath(name, payload) {
      const { idIssuer, tokenId } = this.$route.params;
      return routeNames[name]
        ? routeNames[name](idIssuer, tokenId, payload)
        : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.card.dashboard-activities {
  position: fixed;
  max-width: 350px;
  top: 0;
  right: 0;
  bottom: 0;
  padding-top: 66px;
  overflow-y: auto;
  .d-block {
    &.ion {
      font-size: 20px;
    }
  }
}
</style>
